<template>
  <div class="metadata-section">
    <div class="metadata-container">
      <h4 class="hlt-header h4 weight-600">
        Metadata
      </h4>
      <div class="meta-section">
        <div class="meta-column">
          <div class="module-section section" v-if="assetType != 'grouping' && assetType != 'catalog_page' && assetType != 'catalog'">
            <label
              for="module-content-source"
              class="module-label"
            >Content Source</label>
            <select
              v-model="localAsset.content_source_id"
              name="module-content-source"
              class="hlt-select"
            >
              <option value="null">
                Select
              </option>
              <option
                v-for="(source, i) in contentSources"
                :key="i"
                :value="source.id"
                :source="source"
              >
                {{ source.name }}
              </option>
            </select>
          </div>
          <div class="module-section section" v-if="assetType != 'catalog_page' && assetType != 'catalog'">
            <label
              for="module-tags"
              class="module-label"
            >
              Tags
            </label>
            <tag-section :asset.sync="localAsset" />
          </div>
          <slot name="left" />
        </div>
        <div class="meta-column">
          <div class="asset-activity">
            <label class="weight-600">Activity</label>
            <div class="created-section">
              <p class="meta-text created-container">
                <span class="metadata weight-600">Created:</span>
                <div class="created-by">
                  <img class="created-by-image" :src="asset.created_by_image" v-if="asset.created_by_image" />
                  <user-svg class="user-svg" v-else />
                  <div class="right">
                    <span> {{ asset.created_by_name }}</span>
                    <span> {{ asset.created_by_email }}</span>
                    <span>@  {{ prettyDate(asset.created_at) }}</span>
                  </div>
                </div>
              </p>
            </div>
            <div class="modified-section">
              <p class="meta-text updated-container">
                <span class="metadata weight-600">Last Modified:</span>
                <div class="updated-by">
                  <img class="updated-by-image" :src="asset.updated_by_image" v-if="asset.updated_by_image" />
                  <user-svg class="user-svg" v-else />
                  <div class="right">
                    <span> {{ asset.updated_by_name }}</span>
                    <span> {{ asset.updated_by_email }}</span>
                    <span>@  {{ prettyDate(asset.updated_at) }}</span>
                  </div>
                </div>
              </p>
              <p class="meta-text">
                <span class="metadata weight-600">Revised:</span> <span class="modified" v-if="asset.revised_at">{{ $date(asset.revised_at).format('LL') }}</span><span class="modified" v-else>Unknown</span>
              </p>
            </div>
          </div>

          <div class="linked-to-from-section" v-if="showReverseAssociations">
            <label class="weight-600">Linked from</label>
            <div>
              <div
                v-for="(asset_association) in reverseAssociations"
                :key="'asset_b_asset_associations-' + asset_association.id"
              >
                <router-link
                  :to="qidRoute({type: asset_association.parent_asset_a_type, id: asset_association.parent_asset_a_id})"
                  target="_blank"
                >
                  {{ qidType({asset_type: asset_association.parent_asset_a_type}) }} - {{ asset_association.parent_asset_a_id }}
                </router-link>
              </div>
            </div>
          </div>

          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import date from '../../../mixins/date.js'
import qid from '../../../mixins/qid.js'
import axios from 'axios'
import railsRoutes from '../../../store/rails-routes'
import TagSection from '../shared/Tags'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'
import UserSvg from '@/assets/images/icons/user-solid.svg'

export default {
  components: {
    TagSection,
    UserSvg
  },
  mixins: [
    date,
    qid
  ],
  props: {
    passedAsset: {
      type: Object
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'currentUser',
      'contentSources',
      'assetType',
      'topics',
      'topLevelAsset'
    ]),
    showReverseAssociations () {
      return this.asset.reverse_asset_associations && this.asset.reverse_asset_associations.filter(x => !x.deleted).length > 0
    },
    localAsset () {
      if (this.assetType === 'topic') {
        // needs to use rootAsset not asset so we pass it in
        return this.passedAsset
      } else {
        return this.asset
      }
    },
    reverseAssociations () {
      return orderBy(
        uniqBy(this.asset.reverse_asset_associations.filter(x => !x.deleted), v => [v.parent_asset_a_id, v.parent_asset_a_type].join()),
        ['parent_asset_a_type', 'parent_asset_a_id'],
        ['asc', 'asc']
      )
    }
  },
  created () {
    if (this.currentUser.permissions.can_read_content_sources) {
      axios.get(railsRoutes.api_path({ type: 'content_sources' })).then(response => {
        this.setContentSources(response.data.records)
      })
    }
  },
  methods: {
    ...mapMutations([
      'setContentSources'
    ])
  }
}
</script>

<style lang="scss" scoped>
.metadata-section {
  width: 100%;
  padding-top: 30px;
  border-top: 2px solid $space-gray-20;
  background: $space-gray-30;
  .meta-column {
    padding-right: 30px;
  }
  label {
    font-weight: 600;
    margin-bottom: 4px;
  }
  .metadata-container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .meta-text {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .created-container, .updated-container {
    display: flex;
    flex-direction: column;
  }
  .created-by, .updated-by {
    display: flex;
    font-size: 12px;
    .right {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  .section {
    margin-bottom: 30px;
  }
  .meta-section{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .meta-column {
      flex: 1;
      margin-right: 30px;
    }
  }
  .created-section, .modified-section {
    margin-bottom: 12px;
  }

  .created-by-image, .updated-by-image {
    width: 50px;
    height: 50px;
  }

  .user-svg {
    width: 40px;
    height: 40px;
    fill: $space-gray;
    color: $space-gray;
  }

  .asset-activity {
    margin-bottom: 36px;
  }
  .linked-to-from-section {
    font-size: .8em;
    padding-bottom: 20px;
    a {
      color: $space-black-30;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
